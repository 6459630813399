import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={styles.container}>
      <h1 aria-label="Page not found">
        Error 404<br></br>You didn't break the internet,<br></br>but we can't find this page
      </h1>
      <p role="alert" aria-label="Return Home">
        Head on over to our <a href="/" aria-label="Home page">Home</a> page
      </p>
    </div>
  </Layout>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 10px',
    textAlign: 'center',
    minHeight: '50vh',
  },
};

export default NotFoundPage;
